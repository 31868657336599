export const PATHS = {
    BO: {
        ESTABLISHMENTS: 'backoffice/establecimientos',
        ESTABLISHMENT: 'backoffice/establecimiento',
        ESTABLISHMENTS_CREATE: 'backoffice/establecimientos/crear-establecimiento',
        PRESENTACIONES_MODULE: 'backoffice/presentaciones',
        NOMINA_ASIGNACION: '/backoffice/establecimientos/asignacion-de-nomina'

    },
    SIMP: {
        ESTABLISHMENTS: 'simp/establecimientos',
        ESTABLISHMENT: 'simp/establecimiento',
        ESTABLISHMENTS_CREATE: 'simp/establecimientos/alta-establecimientos',
        NOMINA_ASIGNACION: '/simp/establecimientos/asignacion-de-nomina'
    },
    RAR_PRESENTATION_SIMP: 'simp/establecimientos/presentaciones/crear',
    RAR_DETALLE_SIMP: 'simp/establecimientos/presentaciones/crear-rar/:idRar',
    RAR_PRESENTATION_EDITAR_SIMP: 'simp/establecimientos/presentaciones/editar/rar',
    RAR_DETALLE_EDITAR_SIMP: 'simp/establecimientos/presentaciones/editar-rar/:idRar',
    RAR_DETALLE_EDITAR_SIMP_ESTABLECIMIENTO: 'simp/establecimiento/presentaciones/editar-rar/:idRar',
    RAR_DETALLE_VISUALIZAR_SIMP: 'simp/establecimientos/presentaciones/visualizar-rar/:idRar',
    RAR_DETALLE_VISUALIZAR_BACKOFFICE: 'visualizar-rar/:idRar',



};
