import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { logout, tokenReceived } from '../features/auth/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
    CrearPresentacionDTO,
    getPdfRarResponse,
    presentacionDetalle,
  } from '../pages/Rar/types/types';


const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});


const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();
        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const presentacionesRarApi = createApi({
    reducerPath: 'presentacionRar',
    tagTypes: ['presentacionesRar', 'PresentacionRarById','Responsables'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getDetalleNominaRar: builder.query<
            presentacionDetalle,
            { id_presentacion: number; take: number; skip: number, cuil?: string, expuesto?: boolean }
        >({
            query: ({ id_presentacion, take, skip, cuil, expuesto }) => {
                const params: any = { take, skip };
                if (cuil) params.cuil = cuil;
                if (expuesto) params.expuesto = expuesto;
            
                return {
                  url: `presentaciones-rar/${id_presentacion}/nomina`,
                  params, 
                };
              },
        }),
        createPresentacionRar: builder.mutation({
            query: (formData) => {
                return {
                    url: '/presentaciones-rar',
                    method: 'POST',
                    body: formData
                };
            }
        }),
        createPresentacionRarV2: builder.mutation<void, any>({
            query: (formData) => {
                return {
                    url: 'presentaciones-rar/rar-recibido',
                    method: 'POST',
                    body: formData
                };
            }
        }),
        presentarRar: builder.mutation({
            query: ({ idPresentacion }) => ({
                url: `presentaciones-rar/${idPresentacion}/presentar`,
                method: 'POST'
            }),
            invalidatesTags: ['PresentacionRarById']
        }),
        getPresentacionRarPDFbyId: builder.query<getPdfRarResponse, { idPresentacion: number, typeId: number }>({
            query: ({ idPresentacion, typeId }) => ({
                url: `presentaciones/${idPresentacion}/archivo`,
                params: { idTipo: 2 }
            })
        }),
        getResponsablesByPresentacionId: builder.query<any, { idPresentacion: number }>({
            query: ({ idPresentacion }) => ({
                url: `presentaciones/${idPresentacion}/responsables`,
                method: 'GET',
            }),
            providesTags: ['Responsables']
        }),
    })
});

export const {
    useGetDetalleNominaRarQuery,
    useLazyGetDetalleNominaRarQuery,
    useCreatePresentacionRarMutation,
    useCreatePresentacionRarV2Mutation,
    usePresentarRarMutation,
    useGetPresentacionRarPDFbyIdQuery,
    useGetResponsablesByPresentacionIdQuery,
    useLazyGetPresentacionRarPDFbyIdQuery
} = presentacionesRarApi;