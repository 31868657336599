import React, { useState } from 'react';
import { Grid, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../consts/testIds';
import Input from '../../../components/Input/Input';
import { INTEGERS_UNIT } from '../../../constants/regex';

interface NominaAssignmentsFiltersProps {
    onSearch: (nombre: string, apellido: string, cuil: string) => void;
    onClearFilters: () => void;
}

const NominaAssignmentsFilters: React.FC<NominaAssignmentsFiltersProps> = ({
    onSearch,
    onClearFilters
}) => {
    const { t } = useTranslation('employees');
    const [nombre, setNombre] = useState<string>('');
    const [apellido, setApellido] = useState<string>('');
    const [cuil, setCuil] = useState<string>('');

    const handleSearch = () => {
        onSearch(nombre, apellido, cuil);
    };

    const handleClear = () => {
        setNombre('');
        setApellido('');
        setCuil('');
        onClearFilters();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Input
                    testID={TEST_IDS.payrollAssignment.filters.firstName}
                    label={`${t('AssignmentNomina.first_name')}`}
                    value={nombre}
                    onChange={(e: any) => setNombre(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <Input
                    testID={TEST_IDS.payrollAssignment.filters.cuil}
                    label={`${t('AssignmentNomina.cuil')}`}
                    value={cuil}
                    onChange={(e: any) => {
                        const value = e.target.value;
                        if (
                            value === null ||
                            value === '' ||
                            (INTEGERS_UNIT.test(value) && value?.length <= 11)
                        ) {
                            setCuil(e.target.value);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2
                    }}
                >
                    <Button
                        data-testid={TEST_IDS.payrollAssignment.filters.buttons.clearFilters}
                        variant="text"
                        color="primary"
                        onClick={handleClear}
                        sx={{ mr: 2 }}
                    >
                        {t('AssignmentNomina.clear_filters')}
                    </Button>
                    <Button
                        data-testid={TEST_IDS.payrollAssignment.filters.buttons.search}
                        variant="outlined"
                        color="primary"
                        onClick={handleSearch}
                    >
                        {t('AssignmentNomina.search')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default NominaAssignmentsFilters;
