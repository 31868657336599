import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { logout, tokenReceived } from '../features/auth/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { NewWorkPositionBody } from '../pages/PuestosDeTrabajo/types';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }
        return headers;
    }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const { auth }: any = api.getState();

        const refreshResult = await baseQuery(
            {
                url: '/auth/refresh-token',
                method: 'POST',
                credentials: 'include',
                body: { username: auth?.user?.usuario }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data) {
            api.dispatch(tokenReceived(refreshResult.data));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const workPositionsApi = createApi({
    reducerPath: 'PuestosDeTrabajo',
    tagTypes: ['Sectores', 'Riesgos', 'Puestos'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        createNewWorkPosition: builder.mutation({
            query: (body: NewWorkPositionBody) => ({
                url: `puestos-trabajo`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Puestos']
        }),
        editWorkPositionSectors: builder.mutation({
            query: ({
                workPositionId,
                sectorsIds
            }: {
                workPositionId: number;
                sectorsIds: number[];
            }) => ({
                url: `/puestos-trabajo/${workPositionId}/sectores`,
                method: 'POST',
                body: { idsSectores: sectorsIds }
            }),
            invalidatesTags: ['Puestos']
        }),
        deregisterWorkPosition: builder.mutation({
            query: (idPuesto: number) => ({
                url: `puestos-trabajo/${idPuesto}/estado`,
                method: 'PATCH',
                body: { activo: false }
            }),
            invalidatesTags: ['Puestos']
        }),
        createNewSector: builder.mutation({
            query: (body: { nombre: string }) => ({
                url: `puestos-trabajo/sectores`,
                method: 'POST',
                body
            }),
            invalidatesTags: (result, error) => (error ? [] : ['Sectores'])
        }),
        getWorkPositions: builder.query({
            query: (params) => ({
                url: `puestos-trabajo`,
                params
            }),
            providesTags: ['Puestos']
        }),
        getSectors: builder.query({
            query: () => ({
                url: `puestos-trabajo/sectores`
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Sectores']
        }),
        getWorkPositionSectors: builder.query({
            query: (idPuesto: number) => ({
                url: `puestos-trabajo/${idPuesto}/sectores`
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Sectores']
        }),
        getRisks: builder.query({
            query: () => ({
                url: `puestos-trabajo/riesgos`
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Riesgos']
        }),
        getCIUOs: builder.query({
            query: () => ({
                url: `puestos-trabajo/cious`
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Riesgos']
        })
    })
});

export const {
    useCreateNewWorkPositionMutation,
    useEditWorkPositionSectorsMutation,
    useDeregisterWorkPositionMutation,
    useCreateNewSectorMutation,
    useGetWorkPositionsQuery,
    useGetSectorsQuery,
    useGetWorkPositionSectorsQuery,
    useGetRisksQuery,
    useGetCIUOsQuery
} = workPositionsApi;
