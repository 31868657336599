import { Grid, Typography } from "@mui/material";

interface NoTableDataProps {
    title: string;
}

const NoTableData = ({ title }: NoTableDataProps) => (
    <Grid container justifyContent={'center'} textAlign={'center'}>
        <Typography variant="h6" color={'#476773'} fontFamily={'Roboto, Arial, sans-serif'}>
            {title}
        </Typography>
    </Grid>
);

export default NoTableData;