import React, { useEffect, useState } from 'react';
import {
    Grid,
    Checkbox,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Box,
    Button,
    TableContainer
} from '@mui/material';
import styles from '../EmpresasAsignacion/style.module.css';
import Alert from '../../../components/Alert/AlertDialog';
import { useLocation } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useNomina from './hooks/useNomina';
import NominaAssignmentsFilters from '../components/NominaAssignmentsFilters';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../contexts/TitleContext';
import { TEST_IDS } from '../consts/testIds';
import NoTableData from '../EmpresasAsignacion/components/NoTableData';
import SectionHeader from '../../../components/SectionHeader';

const NominaAsignacion: React.FC = () => {
    const { t } = useTranslation('employees');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const location = useLocation();
    const organismsNames = location.state?.dataOrganismo?.nombre;
    const idOrganismo = location.state?.dataOrganismo?.organismo?.id;
    const contractNumber = location.state?.dataOrganismo?.organismo?.organismoCentral?.contrato;
    const idEstablecimiento = location.state?.dataOrganismo?.id;
    const { setTitle } = useTitle();
    const {
        data,
        totalRecords,
        selectedEmpleados,
        filteredSelectedEmpleados,
        isFetching,
        error,
        handleSelectEmpleado,
        handleGuardar,
        handleClearFilters,
        handleBuscar,
        handleClearSelection
    } = useNomina(contractNumber, idEstablecimiento, idOrganismo, rowsPerPage, page);

    useEffect(() => {
        setTitle(t('AssignmentNomina.page_title'));
    }, [setTitle]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Grid container sx={{ background: '#fff', mb: 3, p: 2 }}>
                <SectionHeader>
                    <SectionHeader.Title>
                        {t('AssignmentNomina.page_title')}: {organismsNames}
                    </SectionHeader.Title>
                </SectionHeader>
                <Grid item xs={12}>
                    <Grid sx={{ p: 2 }}>
                        <Typography
                            variant="h5"
                            sx={{ pl: 0.5, pb: 1, fontWeight: 600, color: '#476773' }}
                        >
                            {t('Filtrar por')}
                        </Typography>
                        <NominaAssignmentsFilters
                            onSearch={handleBuscar}
                            onClearFilters={handleClearFilters}
                        />
                    </Grid>
                </Grid>{' '}
            </Grid>

            <Box sx={{ display: 'flex', gap: 2, maxHeight: '100vh' }} mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={7} sx={{ flex: 1 }}>
                        <Paper elevation={3} className={styles.paper} sx={{ maxHeight: '100%' }}>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: 600, mb: 3, fontSize: '18px', pl: 3, pt: 3 }}
                            >
                                {t('AssignmentNomina.nomina_contrato')}
                            </Typography>
                            <TableContainer sx={{ maxHeight: '620px', overflow: 'auto' }}>
                                {data?.data?.data.length && data?.data?.data.length > 0 ? (
                                    <Table
                                        data-testid={
                                            TEST_IDS.payrollAssignment.tables.contract.testID
                                        }
                                        size="small"
                                        className={styles.smallTable}
                                        stickyHeader
                                    >
                                        <TableHead className={styles.tableHead}>
                                            <TableRow>
                                                <TableCell className={styles.tableCell}></TableCell>
                                                <TableCell className={styles.tableCell}>
                                                    {t('AssignmentNomina.first_name')}
                                                </TableCell>
                                                <TableCell className={styles.tableCell}>
                                                    {t('AssignmentNomina.cuil')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {isFetching ? (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={4}
                                                        align="center"
                                                        className={styles.tableCell}
                                                    >
                                                        <Loading size="medium" />
                                                    </TableCell>
                                                </TableRow>
                                            ) : error ? (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={6}
                                                        align="center"
                                                        style={{ color: 'red' }}
                                                    >
                                                        {t('AssignmentNomina.error_loading_nomina')}
                                                    </TableCell>
                                                </TableRow>
                                            ) : Array.isArray(data?.data?.data) &&
                                              data?.data?.data?.length > 0 ? (
                                                data.data.data.map((empleado: any) => (
                                                    <TableRow
                                                        key={empleado.id}
                                                        className={styles.tableRow}
                                                    >
                                                        <TableCell className={styles.tableCell}>
                                                            <Checkbox
                                                                checked={selectedEmpleados.some(
                                                                    (e) => e.id === empleado.id
                                                                )}
                                                                onChange={() =>
                                                                    handleSelectEmpleado(empleado)
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell className={styles.tableCell}>
                                                            {empleado.nombre}
                                                        </TableCell>
                                                        <TableCell className={styles.tableCell}>
                                                            {empleado.cuil}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        <Typography sx={{ fontWeight: 'bold' }}>
                                                            {t('AssignmentNomina.no_nomina_data')}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                ) : isFetching ? (
                                    <Loading size="medium" />
                                ) : (
                                    <NoTableData title={t('AssignmentNomina.no_nomina_data')} />
                                )}
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={totalRecords}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={t('AssignmentNomina.records_per_page')}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={5} sx={{ flex: 1 }}>
                        <Paper elevation={3} className={styles.paper} sx={{ maxHeight: '100%' }}>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: 600, mb: 3, fontSize: '18px', pl: 3, pt: 3 }}
                            >
                                {t('AssignmentNomina.nomina')}
                            </Typography>
                            <TableContainer sx={{ maxHeight: '620px', overflow: 'auto' }}>
                                {filteredSelectedEmpleados.length > 0 ? (
                                    <Table
                                        data-testid={
                                            TEST_IDS.payrollAssignment.tables.establishment.testID
                                        }
                                        size="small"
                                        className={styles.smallTable}
                                        stickyHeader
                                    >
                                        <TableHead className={styles.tableHead}>
                                            <TableRow>
                                                <TableCell className={styles.tableCell}></TableCell>
                                                <TableCell className={styles.tableCell}>
                                                    {t('AssignmentNomina.first_name')}
                                                </TableCell>
                                                <TableCell className={styles.tableCell}>
                                                    {t('AssignmentNomina.cuil')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredSelectedEmpleados.map((empleado) => (
                                                <TableRow
                                                    key={empleado.id}
                                                    className={styles.tableRow}
                                                >
                                                    <TableCell className={styles.tableCell}>
                                                        <Checkbox
                                                            checked={true}
                                                            onChange={() =>
                                                                handleSelectEmpleado(empleado)
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell className={styles.tableCell}>
                                                        {empleado.nombre}
                                                    </TableCell>
                                                    <TableCell className={styles.tableCell}>
                                                        {empleado.cuil}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : isFetching ? (
                                    <Loading size="medium" />
                                ) : (
                                    <NoTableData
                                        title={t('AssignmentNomina.no_nomina_selected_data_found')}
                                    />
                                )}
                            </TableContainer>
                            <Box sx={{ pb: 2.5, pl: 2, pr: 2, justifySelf: 'flex-end' }}>
                                <Button
                                    data-testid={
                                        TEST_IDS.payrollAssignment.tables.establishment.buttons
                                            .clearSelection
                                    }
                                    variant="text"
                                    color="primary"
                                    onClick={handleClearSelection}
                                    className={styles.button}
                                >
                                    {t('AssignmentNomina.clear_selection')}
                                </Button>
                                <Button
                                    data-testid={
                                        TEST_IDS.payrollAssignment.tables.establishment.buttons.save
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleGuardar(idEstablecimiento)}
                                    className={`${styles.button} ${styles.marginLeft}`}
                                >
                                    {t('AssignmentNomina.save')}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Alert />
        </>
    );
};

export default NominaAsignacion;
