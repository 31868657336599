import { DrawerTitle } from './DrawerTitle';
import { IconHeader } from './IconHeader';
import { memo, ReactNode } from 'react';
import { Title } from './Title';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '../button/Button';
import styles from './styles.module.css';
import TitleSetter from '../TitleSetter';
import { Box, Grid, Typography } from '@mui/material';

interface SectionHeaderProps {
    children: ReactNode;
    renderBack?: boolean;
    containerType?: 'default' | 'drawer';
    title?: string;
    subtitle?: string;
}

function SectionHeader({ children, renderBack, containerType = 'default', title, subtitle }: SectionHeaderProps) {
    const navigate = useNavigate();
    const containerClass =
        containerType === 'drawer' ? styles['header-container-drawer'] : styles['header-container'];

    return (
        <>
            {renderBack && (
                <div className={styles['header-back-btn']}>
                    <Button
                        startIcon={<ArrowBackIcon />}
                        type="outlined"
                        style={{ backgroundColor: 'var(--white)' }}
                        onClick={() => navigate(-1)}
                    >
                        Volver
                    </Button>
                </div>
            )}
            <Grid
                container
                direction={'column'}
                mt={containerType === 'drawer' ? 0 : 2}
                sx={{ backgroundColor: 'white', borderRadius: '5px 5px 0 0' }}
            >
                <div className={containerClass}>
                    <Grid container  justifyContent={'space-between'} alignItems={'center'}>
                        {title && <TitleSetter title={title} />}
                        {children}
                    </Grid>
                    {subtitle && (
                        <Grid item>
                            <Typography
                                variant="subtitle1"
                                pl="10px"
                                sx={{ color: 'text.secondary' }}
                            >
                                {subtitle}
                            </Typography>
                        </Grid>
                    )}
                </div>
            </Grid>
        </>
    );
}

SectionHeader.Title = memo(({ children }: { children: ReactNode }) => {
    if (typeof children === 'string') {
        return (
            <>
                <TitleSetter title={children as string} />
                <Title>{children}</Title>
            </>
        );
    }
    return <Title>{children}</Title>;
});

SectionHeader.DrawerTitle = memo(DrawerTitle);
SectionHeader.IconHeader = memo(IconHeader);


export default SectionHeader;
