import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MassiveUploadState {
    selectedEmployeesIds: number[];
    isDraft: boolean;
}

const initialState: MassiveUploadState = {
    selectedEmployeesIds: [],
    isDraft: false
};


const massiveUploadSlice = createSlice({
  name: 'massiveUpload',
  initialState,
  reducers: {
    setSelectedEmployees: (state, action: PayloadAction<number[]>) => {
      if (action.payload) {
        state.selectedEmployeesIds = action.payload;
      }
    },
    setIsDraft: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.isDraft = action.payload;
      }
    },
  },
});

export const { setSelectedEmployees, setIsDraft} = massiveUploadSlice.actions;
export default massiveUploadSlice.reducer;
