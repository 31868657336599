import { Grid } from '@mui/material';
import Input from '../../../components/Input/Input';
import AutocompleteComponent from '../../../components/layout/Autocomplete';
import { TEST_IDS } from '../consts/testIds';
import { TFunction } from 'i18next';
import { INTEGERS_UNIT } from '../../../constants/regex';

interface WorkPositionsFiltersProps {
    extraFilters: any;
    handleChangeExtraFilters: (e: any) => void;
    t: TFunction
}

const RISK_OPTIONS = [
    { id: 1, value: "true", text: 'Expuesto' },
    { id: 2, value: "false", text: 'No expuesto' },
];

const RarViewFilters = (props: WorkPositionsFiltersProps) => {
    const { extraFilters, handleChangeExtraFilters, t } = props;

    return (
        <>

            <Grid item xs={12} md={6}>
                <Input
                    label={`${t('uploadRar.extraFilters.cuil')}`}
                    size="small"
                    name="cuil"
                    onChange={(e: any) => {
                        const value = e.target.value;
                        if (
                            value === null ||
                            value === '' ||
                            (INTEGERS_UNIT.test(value) && value?.length <= 11)
                        ) {
                            handleChangeExtraFilters(e);
                        }
                    }}
                    value={extraFilters?.cuil || ''}
                    testID={TEST_IDS.rarView.extraFilters.cuil}
                    clearable
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <AutocompleteComponent
                    label={`${t('uploadRar.extraFilters.risks')}`}
                    size="small"
                    name="expuesto"
                    disabled={false}
                    loading={false}
                    onChange={(value: any) => {
                        handleChangeExtraFilters({
                            target: {
                                name: 'expuesto',
                                value: value
                            }
                        });
                    }}
                    options={RISK_OPTIONS}
                    templateLabel={(item: any) => `${item?.text}`}
                    value={extraFilters?.expuesto || ''}
                    testID={TEST_IDS.rarView.extraFilters.risk}
                    clearable
                />
            </Grid>
        </>
    );
};

export { RarViewFilters };
