const TEST_IDS = {
    rarView: {
        extraFilters: {
            fullName: 'uploadRar-filters-fullname',
            cuil: 'uploadRar-filters-cuil',
            risk: 'uploadRar-filters-risk',
            expositionDate: 'uploadRar-filters-expositionDate'
        }
    },
    drawers: {
        gremio: {
            unionFileNumber: 'drawer-gremio-input-unionFileNumber',
            unionName: 'drawer-gremio-input-unionName'
        },
        responsibles: {
            inputs: {
                name: 'drawer-responsibles-input-name',
                lastName: 'drawer-responsibles-input-astName',
                cuit: 'drawer-responsibles-input-cuit',
                cargo: 'drawer-responsibles-input-cargo',
                representation: 'drawer-responsibles-input-representation',
                type: 'drawer-responsibles-input-type',
                grade: 'drawer-responsibles-input-grade',
                matricula: 'drawer-responsibles-input-matricula',
                entity: 'drawer-responsibles-input-entity'
            }
        },
        contractor: {
            cuit: 'drawer-contractor-input-cuit'
        }
    }
};

export { TEST_IDS };
