import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Responsable, ResponsableState } from '../../pages/Rar/types/types';

const initialState: ResponsableState = {
  responsables: [],
  responsableGeneral: null,
  error: null,
};


const responsableSlice = createSlice({
  name: 'responsable',
  initialState,
  reducers: {
    addResponsable: (state, action: PayloadAction<Responsable>) => {
      state.responsables.push(action.payload);
      if (action.payload.responsable) {
        state.responsableGeneral = action.payload;
      }
    },
    updateResponsable: (state, action: PayloadAction<Responsable>) => {
      const index = state.responsables.findIndex((r: Responsable)  => r.id === action.payload.id);
      if (index !== -1) {
        state.responsables[index] = action.payload;
        if (action.payload.responsable) {
          state.responsableGeneral = action.payload;
        }
      }
    },
    removeResponsable: (state, action: PayloadAction<number>) => {
      try {
        state.responsables = state.responsables.filter((r: Responsable) => r.id !== action.payload);
        if (state.responsableGeneral?.id === action.payload) {
          state.responsableGeneral = null;
        }
      } catch (error) {
        state.error = 'Error';  
      }
    },
    setResponsableGeneral: (state, action: PayloadAction<number>) => {
      // const responsable = state.responsables.find((r: Responsable)  => r.id === action.payload) || null;
      // state.responsableGeneral = responsable;
    },
    clearResponsables: (state) => {

      state.responsableGeneral = null;
      state.responsables = [];
    },
    clearError: (state) => {
      state.error = null; 
    },
  },
});

export const { addResponsable, updateResponsable, removeResponsable, setResponsableGeneral, clearResponsables, clearError } = responsableSlice.actions;
export default responsableSlice.reducer;
