import dayjs from 'dayjs';
import { isEqual, sortBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';

export const mimeTypes: { [key: string]: string } = {
    pdf: 'application/pdf',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    // Add other file types as needed
};

export const filterOptions = (options: Array<{}>, inputText: string, idPais: number) => {
    const normalizeText = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const filteredOptionsByIdPais = options?.filter((i: any) => i.idPais === idPais);
    const array = filteredOptionsByIdPais?.filter((i: any) =>
        normalizeText(i.nombre).toLowerCase().includes(normalizeText(inputText).toLowerCase())
    );
    return array || [{ nombre: 'No options', id: 0 }];
};

export const downloadFile = ({ blob, name }: {blob: Blob, name: string}) => {
    const href = URL.createObjectURL(blob);
    const file = document.createElement('a');
    file.href = href;
    file.download = name;
    file.click();
    file.remove();
    URL.revokeObjectURL(href);
};

export const validateAccess = (functions: any, id: number) => {
    let accesible = false;

    if (functions?.funcionalidades.find((i: any) => i.id === id)) accesible = true;

    return accesible;
};

export const getDate = (date: any) => {
    if (!date) return '';
    const newDate = new Date(date);
    return dayjs(newDate.toISOString()).format('DD/MM/YYYY');
};

export const getDateTime = (date: any) => {
    if (!date) return '';
    const newDate = new Date(date);
    return dayjs(newDate.toISOString()).format('DD/MM/YYYY HH:mm');
};

export const getDateTextField = (date: any) => {
    if (!date) return '';
    const [year, month, day] = date.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
};

export const getDateTextFieldFormat = (date: any) => {
    if (!date) return '';
    const [year, month, day] = date.split('T')[0].split('-');
    return `${day}/${month}/${year}`;
};

export const getCurrentDate = (): string => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const downloadBase64File = async (url: string, id: number, name?: string, iteratorIndex?: number) => {
    try {
        const nameSuffix = iteratorIndex ? `_${iteratorIndex}` : ""
        const response = await fetch(url);
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        const fileName = name ? `${name + '-' + id + nameSuffix}.pdf` : `${id}${nameSuffix}.pdf`;
        downloadLink.href = downloadUrl;
        downloadLink.download = fileName;
        downloadLink.click();

        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const downloadBase64FileHelpCenter = async (url: string, name?: string) => {
    try {
        const response = await fetch(url);
        if(response?.statusText === 'Not Found') {
            return "notFound"
        }
        if(!response?.status) {
            return "error"
        }
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        const fileName = `${name}`;
        downloadLink.href = downloadUrl;
        downloadLink.download = fileName;
        downloadLink.click();

        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const handleErrors = (errors: any) => {
    if (Array.isArray(errors?.data?.message)) {
        return errors.data.message.forEach((msg: any) => {
            enqueueSnackbar(msg, {
                variant: 'error'
            });
        });
    } else {
        return enqueueSnackbar(errors?.data?.message || 'Ocurrió un error', {
            variant: 'error'
        });
    }
};

export function filterByKey<T extends Record<string, any>>(data: T[], query: string, key: string) {
    return data.filter((item: T) => item[key] === query);
}

export function urlPatternValidation(url: string): boolean {
    return new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(url);
}

/**
 * Método que formatea una fecha ISO a una fecha local
 * @param fechaISO 
 * @returns  string con la fecha ISO formateada o un string vacio si no se recibe la fecha
 */
export const formatFechaISOToLocal = (fechaISO: string | undefined): string => {
    if (!fechaISO) {
        return ' ';
    }
    return new Date(fechaISO).toLocaleDateString('es-AR');
};