import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

type NoInfoProps = {
    text?: string;
    image?: string;
    title?: string;
    subtitle?: string;
    CustomSubtitle?: React.ReactNode;
};

function NoInfo({ text, image, title, subtitle, CustomSubtitle = null }: NoInfoProps) {
    const { t } = useTranslation('userForm');
    return (
        <Box sx={{ margin: '15px auto', textAlign: 'center' }}>
            {image && (
                <Box
                    component="img"
                    src={image}
                    alt="No info"
                    sx={{ maxWidth: '100%', height: 'auto' }}
                />
            )}
            {(title || text) && (
                <Typography
                    variant="h4"
                    component="div"
                    sx={{ marginTop: '35px', fontWeight: 'bold' }}
                >
                    {title || t(text || 'noinfo')}
                </Typography>
            )}
            {CustomSubtitle ? (
                <Box sx={{ marginTop: '10px', color: 'text.secondary' }}>{CustomSubtitle}</Box>
            ) : (
                subtitle && (
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{ marginTop: '10px', color: '#384A52', fontSize: '16px', lineHeight: '18.75px', fontWeight: '400' }}
                    >
                        {subtitle}
                    </Typography>
                )
            )}
        </Box>
    );
}

export default NoInfo;
