import React, { useEffect, useMemo, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import Input from '../../../components/Input/Input';
import AutocompleteComponent from '../../../components/layout/Autocomplete';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../../components/layout/DatePicker';
import { PRESENTATION_STATE, PRESENTATION_TYPE } from '../consts';
import ClearIcon from '@mui/icons-material/Clear';
import { useGetCentralOrganismsQuery } from '../../../services/organismsApi';
import { useGetEmpresasQuery } from '../../../services/companyApi';
import { useAppSelector } from '../../../hooks/reduxHooks';
import dayjs from 'dayjs';

interface FiltersProps {
  extraFilters: any;
  handleChangeExtraFilters: (e: any) => void;
  isShowMoreFiltersOpen: boolean;
}

const FiltersComponent: React.FC<FiltersProps> = ({ extraFilters, handleChangeExtraFilters, isShowMoreFiltersOpen  }) => {
  const { t } = useTranslation('presentaciones');
  const { data: establishmentsData } = useGetEmpresasQuery({
    skip: 0,
    take: 1000,
  });

  const userData = useAppSelector((state) => state.auth.user);
  const EXCLUDED_CUITS = ['30500052089', '30707519092'];

  const contractOptions = useMemo(() => {
    return (
      userData?.organismos
        ?.filter((organismo: any) => !EXCLUDED_CUITS.includes(organismo.cuit))
        .map((organismo: any) => ({
          label: `${organismo.cuit} - ${organismo.nombre}`, 
          value: organismo.id,
        })) || []
    );
  }, [userData]);

  const [selectedContractId, setSelectedContractId] = useState<number | null>(null);

  
  const filteredEstablishments = useMemo(() => {
    if (!selectedContractId || !Array.isArray(establishmentsData?.data?.data)) {
      return [];
    }
    return establishmentsData.data.data.filter(
      (establishment: { organismo: { id: number } }) =>
        establishment.organismo?.id === selectedContractId
    );
  }, [selectedContractId, establishmentsData]);


  const handleContractChange = (value: any) => {
    const newContractId = value?.value || null;
    setSelectedContractId(newContractId);
  };


  useEffect(() => {
    if (extraFilters?.contrato === null || extraFilters?.contrato === undefined) {
      setSelectedContractId(null);
    }
  }, [extraFilters?.contrato]);
  
  const presentationTypeOptions = Object.entries(PRESENTATION_TYPE)
  .filter(([key, value]) => typeof value === 'number') 
  .map(([key, value]) => ({
    label: t(`types.${key.toLowerCase()}`), 
    value, 
  }));

  const presentationStateOptions = Object.entries(PRESENTATION_STATE)
  .filter(
    ([key, value]) =>
      typeof value === 'number' &&
      !['BORRADOR', 'PROCESANDO', 'ERROR_PROCESAMIENTO'].includes(key)
  ) 
  .map(([key, value]) => ({
    label: key === 'CANCELADO' ? 'RECHAZADO' : key.replace(/_/g, ' '),
    value,
  }));

  const clearDate = (field: string) => {
    handleChangeExtraFilters({ target: { name: field, value: null } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
      <AutocompleteComponent
        name="idContrato"
        label={t('filters.organism')}
        value={contractOptions.find((option: any) => option.value === extraFilters?.idContrato) || null}
        options={contractOptions}
        templateLabel={(option: any) => option?.label || ''}
        onChange={(value: any) => {
          handleContractChange(value);
          handleChangeExtraFilters({
            target: { name: 'idContrato', value: value?.value || '' },
          });
        }}
      />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
      <AutocompleteComponent
        name="idEstablecimiento"
        label={t('filters.establishment')}
        value={
          filteredEstablishments.find(
            (establishment: { id: any }) => establishment.id === extraFilters?.idEstablecimiento
          )
            ? {
                label: `${filteredEstablishments.find(
                  (establishment: { id: any }) => establishment.id === extraFilters?.idEstablecimiento
                )?.nombre} (${t('filters.codSRT')}: ${
                  filteredEstablishments.find(
                    (establishment: { id: any }) => establishment.id === extraFilters?.idEstablecimiento
                  )?.codigoSRT
                })`,
                value: extraFilters?.idEstablecimiento,
              }
            : null
        }
        options={filteredEstablishments.map(
          (establishment: { nombre: any; id: any; codigoSRT: any }) => ({
            label: `${establishment.nombre} (${t('filters.codSRT')}: ${establishment.codigoSRT})`,
            value: establishment.id,
          })
        )}
        templateLabel={(option: any) => option?.label || ''}
        onChange={(value: any) => {
          handleChangeExtraFilters({
            target: { name: 'idEstablecimiento', value: value?.value || '' },
          });
        }}
      />

       
      </Grid>

      {isShowMoreFiltersOpen && (
        <>

        <Grid item xs={12} sm={6} md={3}>
          <AutocompleteComponent
            name="idTipo"
            label={t('filters.presentationType')}
            value={presentationTypeOptions.find(option => option.value === extraFilters?.idTipo) || null}
            options={presentationTypeOptions}
            templateLabel={(option: any) => option?.label || ''}
            onChange={(value: any) =>
              handleChangeExtraFilters({ target: { name: 'idTipo', value: value?.value } })
            }
          />
        </Grid>


        <Grid item xs={12} sm={6} md={3}>
        <AutocompleteComponent
          name="estado"
          label={t('filters.state')}
          value={presentationStateOptions.find(option => option.value === extraFilters?.estado) || null}
          options={presentationStateOptions}
          templateLabel={(option: any) => option?.label || ''}
          onChange={(value: any) =>
            handleChangeExtraFilters({ target: { name: 'estado', value: value?.value } })
          }
        />
          </Grid>

          <Grid item xs={12} sm={6} md={2.9} style={{ position: 'relative' }}>
          <DatePicker
            name="fechaDesde"
            label={t('filters.startDate')}
            value={extraFilters?.fechaDesde || null}
            onChange={(date: Date | null) => {
              if (date && extraFilters?.fechaHasta && dayjs(date).isAfter(dayjs(extraFilters.fechaHasta))) {
                handleChangeExtraFilters({ target: { name: 'fechaHasta', value: null } });
              }
              handleChangeExtraFilters({ target: { name: 'fechaDesde', value: date } });
            }}
            disableFuture={true}
            format="DD/MM/YYYY"
          />
            {extraFilters?.fechaDesde && (
              <IconButton
                onClick={() => clearDate('fechaDesde')}
                style={{ position: 'absolute', top: 28, right: 40 }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={0.2} display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h3" sx={{ fontWeight: 'bold', mt: 2, color:'#707070' }}>
              -
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2.9} style={{ position: 'relative' }}>
          <DatePicker
              name="fechaHasta"
              label={t('filters.endDate')}
              value={extraFilters?.fechaHasta || null}
              onChange={(date: Date | null) => {
                if (date && extraFilters?.fechaDesde && dayjs(date).isBefore(dayjs(extraFilters.fechaDesde))) {
                  handleChangeExtraFilters({ target: { name: 'fechaDesde', value: null } });
                }
                handleChangeExtraFilters({ target: { name: 'fechaHasta', value: date } });
              }}
              disableFuture={true}
              minDate={extraFilters?.fechaDesde || null}
              format="DD/MM/YYYY"
            />
            {extraFilters?.fechaHasta && (
              <IconButton
                onClick={() => clearDate('fechaHasta')}
                style={{ position: 'absolute', top: 28, right: 40 }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FiltersComponent;

