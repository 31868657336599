import React, { useCallback, useEffect, useState } from 'react';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import useNominaRar from '../../hooks/useNominaRar';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    styled,
    Typography
} from '@mui/material';
import BackdropComponent from '../../../../components/Backdrop/BackdropComponent';
import useGlobalFilters from '../../../../hooks/useGlobalFilters';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../contexts/TitleContext';
import { useGetResponsablesByPresentacionIdQuery } from '../../../../services/presentacionesRarApi';
import useToggle from '../../../../components/Cabeceras/hooks/useToggle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getDateTextField } from '../../../../utils/common';
import { RarViewFilters } from '../RarViewFilters';
import SearchToolbar from '../../../../components/SearchToolbar/SearchToolbar';

export enum NOMINA_STATE {
    OK = 'OK',
    INFO = 'INFO',
    ERROR = 'ERROR'
}

interface Column {
    title: string;
    render: (row: any) => React.ReactNode;
    align?: 'left' | 'center' | 'right';
}

const RarView = () => {
    const presentacionItem = useAppSelector((state) => state.rarPresentacion.presentacionItem);
    const { setTitle } = useTitle();

    const { t } = useTranslation('presentacionesRar');
    const {
        filters,
        setFilters,
        handleSubmitSearch,
        clearFilters,
        extraFilters,
        handleChangeExtraFilters
    } = useGlobalFilters();

    const [isExpanded, toggleExpanded] = useToggle(true);
    const {
        presentacionDetalle,
        isLoadingDetalle,
        isLoadingDescarga,
        isLoadingCambioEstado,
        handleClickVolver,
        fetchDetalleNomina
    } = useNominaRar(filters, presentacionItem?.id);

    const {
        data: responsables,
        isLoading,
        error
    } = useGetResponsablesByPresentacionIdQuery(
        { idPresentacion: presentacionItem?.id ?? 0 },
        { skip: !presentacionItem }
    );

    useEffect(() => {
        if (presentacionItem) {
            fetchDetalleNomina();
        }
    }, [presentacionItem, fetchDetalleNomina]);

    useEffect(() => {
        if (presentacionItem) {
            localStorage.setItem('selectedPresentationId', presentacionItem?.id.toString());
        }
    }, [presentacionItem]);

    useEffect(() => {
        setTitle(t('headerRar.title'));
    }, [setTitle]);

    const ExpandableItemWrapper = styled('div')(({ theme }) => ({
        marginLeft: '25px'
    }));

    const TitleWrapper = styled('div')({
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    });

    const iconButtonStyle = {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        marginLeft: '15px',
        alignSelf: 'center'
    };

    const columns: Column[] = [
        { title: t('tableNominaDetail.columns.cuil'), render: (row) => row.cuil },
        {
            title: t('tableNominaDetail.columns.fullName'),
            render: (row) => `${row.nombre} ${row.apellido}`
        },
        { title: t('tableNominaDetail.columns.ingressDate'), render: (row) => getDateTextField(row.fechaIngreso) },
        {
            title: t('tableNominaDetail.columns.expositionDate'),
            render: (row) => getDateTextField(row.fechaInicioExposicion)
        },
        { title: t('tableNominaDetail.columns.sector'), render: (row) => row.sector },
        { title: t('tableNominaDetail.columns.Job'), render: (row) => row.puesto },
        {
            title: t('tableNominaDetail.columns.risk'),
            render: (row: any) => {
                if (!row?.riesgos || !Array.isArray(row.riesgos) || row.riesgos.length === 0) {
                    return '-';
                }
            
                const formattedValues = row.riesgos
                    .map((risk: any) => risk.codigo === "00000" ? t("labels.notExposed") : risk.codigo)
                    .filter((value: any) => value);
            
                return formattedValues.length > 0 ? formattedValues.join(', ') : '-';
            }
        }
    ];

    return (
        <div>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                spacing={3}
                sx={{
                    '& .MuiGrid-root': {
                        width: '100%'
                    }
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <ExpandableItemWrapper sx={{ background: 'white', pl: '58px', mt: '-0px' }}>
                        <TitleWrapper>
                            <Typography variant="h3" sx={{ fontWeight: '600', color: '#1E272E' }}>
                                {t('responsableData.title')}
                            </Typography>
                            <IconButton onClick={toggleExpanded} style={iconButtonStyle}>
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </TitleWrapper>
                        <Collapse in={isExpanded}>
                            {responsables?.data
                                ?.filter(
                                    (data: { cargo: { codigo: string } }) =>
                                        data?.cargo?.codigo === 'R'
                                )
                                .map(
                                    (
                                        data: {
                                            nombre: string;
                                            apellido: string;
                                            cuit: string;
                                            cargo: { nombre: string };
                                            tipoContratacion: string | { nombre: string };
                                            titulo: string;
                                            matricula: string;
                                            entidadOtorgante: string;
                                        },
                                        index: number
                                    ) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{ pb: 4, pl: '1px' }}
                                            key={`${data.cuit}-${index}`}
                                        >
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.name')}:</strong>
                                                    <br />
                                                    {data.nombre || ''} {data.apellido || ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.CUIT')}:</strong>
                                                    <br />
                                                    {data.cuit || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.cargo')}:</strong>
                                                    <br />
                                                    {data.cargo?.nombre || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>{t('responsableData.status')}:</strong>
                                                    <br />
                                                    {typeof data.tipoContratacion === 'string'
                                                    ? data.tipoContratacion
                                                    : data.tipoContratacion?.nombre || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1">
                                                    <strong>
                                                        {t('responsableData.tituloHabilitante')}:
                                                    </strong>
                                                    <br />
                                                    {data.titulo || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography variant="body1">
                                                    <strong>
                                                        {t('responsableData.matriculaNumber')}:
                                                    </strong>
                                                    <br />
                                                    {data.matricula || 'N/A'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">
                                                    <strong>
                                                        {t('responsableData.entidadOtorgante')}:
                                                    </strong>
                                                    <br />
                                                    {data.entidadOtorgante || 'N/A'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                )}
                        </Collapse>
                    </ExpandableItemWrapper>
                </Box>
                <Grid item xs={12}>
                        <SearchToolbar
                            inputSearchName=""
                            onChange={() => {}}
                            onClick={handleSubmitSearch}
                            showSearchInput={false}
                            clearFilters={clearFilters}
                        >
                            <RarViewFilters
                                extraFilters={extraFilters}
                                handleChangeExtraFilters={handleChangeExtraFilters}
                                t={t}
                            />
                        </SearchToolbar>
                    </Grid>

                <Grid item xs={12} lg={12}>
                    <CustomTable
                        columns={columns}
                        isLoading={isLoadingDetalle}
                        data={presentacionDetalle?.data?.data ?? []}
                        count={presentacionDetalle?.data?.count}
                        filters={filters}
                        setFilters={setFilters}
                        noInfoTitle={t('tableNominaDetail.noResultsTitle')}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        onClick={handleClickVolver}
                        style={{
                            borderRadius: '8px',
                            height: '48px',
                            width: 'auto'
                        }}
                    >
                        {t('buttons.goBack')}
                    </Button>
                </Grid>
            </Grid>
            <BackdropComponent
                loading={isLoadingDetalle || isLoadingDescarga || isLoadingCambioEstado}
            />
        </div>
    );
};

export default RarView;
