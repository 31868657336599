import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

interface EstablishmentState {
    selectedEstablishmentId: number | null;
    selectedEstablishmentIdFilter: number | null;
    selectedEstablishmentName: string | null;
    selectedEstablishmentIdOrganismId: number | null;
}

const initialState: EstablishmentState = {
    selectedEstablishmentId: null,
    selectedEstablishmentIdFilter: null,
    selectedEstablishmentName: null,
    selectedEstablishmentIdOrganismId: null
};

const establishmentSlice = createSlice({
  name: 'establishment',
  initialState,
  reducers: {
    setEstablishmentId: (state, action: PayloadAction<number | null>) => {
      state.selectedEstablishmentId = action.payload;
    },
    setEstablishmentOrganismId: (state, action: PayloadAction<number | null>) => {
      state.selectedEstablishmentIdOrganismId = action.payload;
    },
    setEstablishmentIdFilter: (state, action: PayloadAction<number | null>) => {
      state.selectedEstablishmentIdFilter = action.payload;
    },
    setEstablishmentName: (state, action: PayloadAction<string | null>) => {
      state.selectedEstablishmentName = action.payload;
    },
    clearEstablishmentId: (state) => {
      state.selectedEstablishmentId = null;
    },
    clearEstablishmentIdFilter: (state) => {
      state.selectedEstablishmentIdFilter = null;
    },
    clearEstablishmentName: (state) => {
      state.selectedEstablishmentName = null;
    },
  },
});

export const {
    setEstablishmentId,
    clearEstablishmentId,
    setEstablishmentIdFilter,
    setEstablishmentName,
    clearEstablishmentName,
    clearEstablishmentIdFilter,
    setEstablishmentOrganismId
} = establishmentSlice.actions;
const persistConfig = {
  key: 'establishment',
  storage,
};

export default persistReducer(persistConfig, establishmentSlice.reducer);
// export default establishmentSlice.reducer;